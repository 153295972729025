//Mobile menu btn
$(function () {
    $('.menu-btn').click(function () {
        $('.menu').fadeIn().addClass('opened');
    });

    $('.menu i').click(function () {
        $('.menu').fadeOut().removeClass('opened');
    })
});

//Tabs
$(function () {
    $('.tabs__titles span').click(function () {
        var parent = $(this).closest('.tabs'),
            content = parent.find('.tabs__contents > div').eq($(this).index());
        $(this).addClass('active').siblings('.active').removeClass('active');
        content.siblings(':visible').fadeOut('fast', function () {
            content.fadeIn('fast');
        });
    })
});

$(function () {
    $('.first-display .right-block .top a').click(function () {
        $('#callback [name="speciality"] option:nth-child(' + ($(this).index() + 2) + ')').attr('selected', 'selected');
    });
});